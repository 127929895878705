import CategoryTile from 'components/CategoryTile'
import InfiniteHits from 'components/InfiniteHits'
import InitiativeTile from 'components/InitiativeTile'
import Intro from 'components/Intro'
import PromotedTile from 'components/PromotedTile'
import SEO from 'components/seo'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Index } from 'react-instantsearch-dom'
import { Container } from 'theme-ui'
import GraphQLErrorList from '../components/graphql-error-list'

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

const EASE = `cubic-bezier(0.59, 0.01, 0.28, 1)`
// const EASE = `linear`

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      ...LocalizedSettings
      aboutPage {
        _rawModules(resolveReferences: {maxDepth: 10})
        i18n_refs {
          ref {
            ... on SanityPage {
              _rawModules(resolveReferences: {maxDepth: 10})
              i18n_lang
            }
          }
        }
      }
      backgroundImage {
        asset {
          id
          url
        }
      }
      orderCategories {
        _id
        icon {
          asset {
            url
          }
        }
        description {
          localized
        }
        title {
          localized
        }
      }
    }
  }
`

function filterForPromoted({ _rawModules }) {
  if (!_rawModules) return [];
  return _rawModules.filter(module => module.promoted)
}

// searchState.menu categories.title.localized
function filterForActiveCategory(nodes, menu) {
  if (!nodes) return [];
  return nodes.filter(node => {
    return menu['categories.title.localized'] === node.title.localized
  }).map(node => ({
    ...node,
    size: 'category',
    alignSelf: 'center'
  }))
}

const IndexPage = props => {
  const { data, errors, searchState = {}, showIntro, refineRef, pageContext } = props
  const { language, isDefault } = pageContext

  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    )
  }

  const site = (data || {}).site
  const orderCategories = (site || {}).orderCategories

  if (!site) {
    throw new Error(
      "Missing 'Site settings'. Open the studio at http://localhost:3333 and add some content to 'Site settings' and restart the development server."
    )
  }

  const isRefined = (searchState.menu && searchState.menu['categories.title.localized'] && searchState.menu['categories.title.localized'].length > 0) || searchState.query?.length > 0

  const activeCategories = orderCategories && searchState.menu
    ? filterForActiveCategory(orderCategories, searchState.menu).map((item) => ({
      ...item,
      slotComponent: CategoryTile
    }))
    : []

  // get current language about page from refs of base language about page
  let i18nAboutPage = site.aboutPage
  if (!isDefault) {
    i18nAboutPage = i18nAboutPage.i18n_refs?.find(({ref}) => ref.i18n_lang === pageContext.language)?.ref || false
    // we might not have a translation of the about page
    if (!i18nAboutPage) i18nAboutPage = site.aboutPage
  }

  const promotedModules = i18nAboutPage
    ? filterForPromoted(i18nAboutPage).map((item) => ({
      ...item,
      slotComponent: PromotedTile
    }))
    : []

  const categories = (orderCategories || []).map((item) => ({
    ...item,
    size: 'category',
    refineRef,
    clickable: !isRefined,
    slotComponent: CategoryTile
  }))


  const firstPromoted = promotedModules.shift()
  const shuffledPromotedModules = shuffle(promotedModules)

  const mixed = categories.map((category, position) => {
    return [
      category,
      position % 3 === 0 && shuffledPromotedModules.length > 0 ? shuffledPromotedModules.pop() : undefined
    ]
  }).flat(2).filter(Boolean)

  const injectedModules = isRefined ? activeCategories : [firstPromoted, ...mixed]

  const slots = [
    {
      injectAt: ({ position }) => position % 3 === 0,
      getHits: ({ position, resultsByIndex }) => {
        const index = position / 3
        const item = injectedModules[index]
        return item ? [item] : []
      }
    },
  ]

  const [y, setY] = useState(showIntro ? '100%' : '0')
  useEffect(() => {
    // delay the enter animation
    setTimeout(() => { setY('0') }, 2000)
  }, [])

  return (
    <React.Fragment>
      <SEO title={site.title} description={site.description?.localized} />
      <Intro backgroundImage={data.site.backgroundImage} showIntro={showIntro} />
      <Container
        pt={9}
        px={2}
        bg={'white'}
        sx={{
          minHeight: '100vh',
          flexGrow: '1',
          borderTopLeftRadius: 'default',
          borderTopRightRadius: 'default',
          transform: `translateY(${y})`,
          transition: `transform 1000ms ${EASE}`
        }}
      >
        <InfiniteHits
          slots={() => slots}
          hitComponent={InitiativeTile}
          isRefined={isRefined}
        />
      </Container>
    </React.Fragment>
  )
}

export default IndexPage
