import React, { useState, useEffect } from 'react'
import { Box } from 'theme-ui'
import logo from 'img/schwarz-foundation-logo-white.svg'

const Logo = () =>
  <Box sx={{ gridColumn: ['span 3', null, 'span 2'] }}>
    <img width='100%' height='auto' src={logo} />
  </Box>

const EASE = `cubic-bezier(0.59, 0.01, 0.28, 1)`

const Intro = ({ backgroundImage, showIntro }) => {

  const [visibility, setVisibility ] = useState(showIntro)
  useEffect(() => {
    if (showIntro === false) {
      setTimeout(() => {
        setVisibility(false)
      }, 1000)
    }
  }, [showIntro])

  return (
    <Box
      sx={{
        visibility: visibility ? 'visible' : 'hidden',
        p: 2,
        backgroundImage: `url(${backgroundImage?.asset?.url})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: -1,
        position: 'fixed'
      }}
      className='css-grid'
    >
      <Logo />
    </Box>
  )
}

export default Intro
