/** @jsx jsx */
import { jsx, Box, Heading } from 'theme-ui'
import React, { useEffect, useRef, useState } from "react";
import { Flipper, Flipped } from 'react-flip-toolkit'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import { connectInjectedHits } from './connectInjectedHits'
import { sizeToCol } from 'lib/helpers'

// @TODO: consider search result caching
// https://www.algolia.com/doc/api-client/advanced/configure-the-client/javascript/?language=javascript&client=javascript#cache-on-hosts

const InfiniteHits = React.memo(({ injectedHits, hasMore, refineNext }) => {

  let sentinel = useRef(null)
  function onSentinelIntersection (entries){
    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  useEffect(() => {
    let observer = new IntersectionObserver(onSentinelIntersection, {})
    observer.observe(sentinel.current)

    return () => observer.disconnect()
  }, [injectedHits])

  return (
    <Box sx={{pt: 2, pb: 8}}>
      <Box sx={{ gridColumn: '1/13' }} >
        <Flipper flipKey={injectedHits.map(({ props }) => props.hit.objectID).join('')} spring={{ stiffness: 400, damping: 65 }}>
          <ul className='css-grid grid-end' sx={{ listStyle: 'none', p: 0, m: 0}}>
              {(injectedHits || []).map(({ props, type, Hit }) => {
                return (
                  <Flipped key={props.hit._key || props.hit._key || props.hit.objectID} flipId={props.hit._key || props.hit.objectID}>
                    <li sx={{ ...sizeToCol(props.hit.size), alignSelf: props.alignSelf }}><Hit {...props.hit} /></li>
                  </Flipped>
                )
              })
            }
          </ul>
        </Flipper>
        <div ref={sentinel}/>
      </Box>
    </Box>
  )
}, (prevProps, nextProps) => { // this is an 'areEqual' callback
  if (prevProps.isRefined !== nextProps.isRefined) {
    return false
  }

  const prevItems = prevProps.injectedHits.filter(item => item.type === 'item') || []
  const nextItems = nextProps.injectedHits.filter(item => item.type === 'item') || []
  if (prevItems.length !== nextItems.length) {
    return false
  }
  // use nested objectID to compare
  for (let i = 0; i < nextItems.length; i++) {
    if (!prevItems.find(item => item.props.hit.objectID === nextItems[i].props.hit.objectID)) {
      return false
    }
  }
  return true
})

export default connectInfiniteHits(connectInjectedHits(InfiniteHits))
