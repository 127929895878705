import React from 'react'
import { Heading, Text, Box } from 'theme-ui'
import Corner from 'components/Corner'

const Category = ({ size, icon }) =>
  <Box
    sx={{
      width: ['25vw', null, '16.6666vw', '8.3333vw'],
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 200,
      'img': {
        width: 'calc(100% - 10px)',
        display: 'block',
      }
    }}
  >
  <img width='100%' height='auto' src={icon} />
</Box>

const Caption = ({size, title}) =>
  <Box sx={{
    transition: 'opacity .5s, transform .25s',
    transform: 'translate(0, 0)',
    opacity: 1,
    position: 'absolute',
    zIndex: '200',
    bottom: '0',
    right: 0,
    variant: 'text.fms',
    'width': ['33%', null, null, '66%'],
    p: '3pt 5pt 0',
    borderTopLeftRadius: 'default',
    borderBottom: 'none',
    bg: 'white'
  }}>
    <Box sx={{position: 'absolute', bottom: `100%`, right: `0`}}><Corner/></Box>
    <Box sx={{position: 'absolute', bottom: `0`, right: `100%`}}><Corner /></Box>
    {`${title}`}
  </Box>

const CategoryTile = ({ currentRefinement, _id, icon, description, refineRef, clickable, title, size }) => {
  const handleClick = () => {
    if (refineRef && title && clickable) {
      refineRef.current(title.localized)
    }
  }
  return (
    <Box sx={{ position: 'relative', cursor: clickable && 'pointer' }} onClick={handleClick}>
      <Category size={size} icon={icon?.asset?.url} />
      <Box
        sx={{
          bg: 'secondary',
          minHeight: '13rem',
          ml: ['25vw', null, '16.6666vw', '8.3333vw'],
          color: 'black',
          border: 'none',
          borderRadius: 'default',
          padding: 1,
          pb: 6,
          'p': {
            mb: 1,
            variant: 'text.frm',
          }
        }}
      >
        <p>{description?.localized}</p>
        <Caption size={size} title={title?.localized}  />
      </Box>
    </Box>
  )
}

export default CategoryTile
