import React from 'react'
import PageRichTextModule from 'components/Modules/PageRichTextModule'
import clientConfig from '../../client-config'
import BaseBlockContent from '@sanity/block-content-to-react'

const PromotedTile = (hit) =>
  <BaseBlockContent
    blocks={[hit]}
    serializers={{ types: { blockTextModule: (props) => <PageRichTextModule {...props} /> } }}
    {...clientConfig.sanity}
  />

export default PromotedTile